import React, { useRef, useEffect, FC, ReactElement } from 'react';
import { Link, graphql } from 'gatsby';
import Bio from '../../components/Bio';
import Triangle from '../../components/Triangle';
import Section from '../../components/Section';
import theme from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SEO from '../../components/SEO';
import './style.css';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { formatPostDate, formatReadingTime } from '../../utils/helpers';
// import get from 'lodash/get';

import BlogLayout from '../../components/BlogLayout';

const systemFont = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`;

interface FrontmatterObject {
  title: string;
  spoiler: string;
  slug: string;
  date: string;
}

interface PageObject {
  frontmatter: FrontmatterObject;
}

interface PageContext {
  previous: PageObject;
  next: PageObject;
}

interface MarkdownObject {
  timeToRead: string;
  frontmatter: FrontmatterObject;
  html: string;
}

interface DataObject {
  markdownRemark: MarkdownObject;
}

interface LocationObject {
  frontmatter: FrontmatterObject;
  html: string;
}

type ChildProps = {
  data: DataObject;
  location: LocationObject;
  pageContext: PageContext;
};

const GITHUB_USERNAME = 'ajaykumar97';
const GITHUB_REPO_NAME = 'ajay-kumar';

const Template: FC<ChildProps> = ({
  data, // this prop will be injected by the GraphQL query below.
  // location = {},
  pageContext,
}): ReactElement => {
  const { previous, next } = pageContext;
  const scrollTopRef = useRef<HTMLButtonElement>(null);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    if (
      (document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20) &&
      scrollTopRef.current !== null
    ) {
      scrollTopRef.current.style.display = 'block';
    } else {
      if (scrollTopRef.current !== null)
        scrollTopRef.current.style.display = 'none';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const flags = useFlags();
  console.log('flags', flags);

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const siteTitle = 'Writings by Ajay';
  const langKey = '';

  const editUrl = `https://github.com/${GITHUB_USERNAME}/${GITHUB_REPO_NAME}/edit/master/src/pages/${frontmatter.slug}/index.md`;

  return (
    <Section.Container id="home" Background={Background}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.spoiler}
        slug={frontmatter.slug}
      />
      <BlogLayout title={siteTitle}>
        <main>
          <article>
            <header>
              <h1 style={{ color: 'var(--textTitle)' }}>{frontmatter.title}</h1>
              <p
                style={{
                  // ...scale(-1 / 5),
                  display: 'block',
                  // marginBottom: rhythm(1),
                  // marginTop: rhythm(-4 / 5),
                }}
              >
                {formatPostDate(frontmatter.date, langKey)}
                {` • ${formatReadingTime(markdownRemark.timeToRead)}`}
              </p>
            </header>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </article>
        </main>
        {flags?.editOnGithub && (
          <footer>
            <p>
              <a
                href={editUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'rgb(9, 1, 95)' }}
              >
                Suggest An Edit
              </a>
            </p>
          </footer>
        )}
        <aside>
          <nav>
            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                listStyle: 'none',
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link
                    to={'/writing/' + previous.frontmatter.slug}
                    rel="prev"
                    style={{ marginRight: 20, color: 'rgb(9, 1, 95)' }}
                  >
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link
                    to={'/writing/' + next.frontmatter.slug}
                    rel="next"
                    style={{ color: 'rgb(9, 1, 95)' }}
                  >
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </aside>
        <button
          onClick={scrollToTop}
          ref={scrollTopRef}
          className="scroll-to-top"
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </button>
      </BlogLayout>
    </Section.Container>
  );
};
const Background = () => (
  <>
    <Triangle
      color="muted"
      theme={theme}
      height={['35vh', '80vh']}
      width={['95vw', '60vw']}
    />

    <Triangle
      color="secondary"
      theme={theme}
      height={['38vh', '80vh']}
      width={['50vw', '35vw']}
    />

    <Triangle
      color="primary"
      theme={theme}
      height={['25vh', '35vh']}
      width={['75vw', '60vw']}
      position="top-right"
    />

    <Triangle
      color="muted"
      theme={theme}
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
  </>
);

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

export default Template;
